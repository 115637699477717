// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import {
  groupIdSchema,
  lcNameSchema,
  userIdSchema,
  type PartId,
} from "./ids.js";
import { TypeMapId, ajvToJsonSchema, create_io } from "./ajv.js";
import { Type, StaticDecode } from "@sinclair/typebox";

export const partSchema = Type.Object(
  {
    name: Type.String(),
    members: Type.Array(userIdSchema, { uniqueItems: true }),
    backups: Type.Array(userIdSchema, { uniqueItems: true }),
  },
  { additionalProperties: false },
);

export const groupSchema = Type.Object(
  {
    name: Type.String(),
    groupname: groupIdSchema,
    url_prefix: Type.Optional(Type.String({ format: "url", pattern: "/$" })),
    website: Type.Optional(Type.String()),
    admins: Type.Array(userIdSchema, { minItems: 1, uniqueItems: true }),
    members: Type.Array(userIdSchema, { minItems: 1, uniqueItems: true }),
    parts: TypeMapId<PartId, typeof partSchema>(partSchema, [lcNameSchema]),
  },
  { additionalProperties: false },
);

export type Group = StaticDecode<typeof groupSchema>;
export type Part = StaticDecode<typeof partSchema>;

const groupAjvSchema = {
  ...groupSchema,
  $defs: { lcname: lcNameSchema },
};
const groupArraySchema = Type.Array(groupSchema, {
  minItems: 1,
  uniqueItems: true,
});
const groupArrayAjvSchema = {
  ...groupArraySchema,
  $defs: { lcname: lcNameSchema },
};
export const group_io = create_io(groupAjvSchema, [lcNameSchema]);
export const groups_io = create_io(groupArrayAjvSchema, [lcNameSchema]);

export function find_group(
  groups: Group[],
  groupname: string,
): Group | undefined {
  return groups.find((g) => g.groupname === groupname);
}

export function replace_group(groups: Group[], group: Group) {
  const index = groups.findIndex((g) => g.groupname === group.groupname);
  if (index !== -1) {
    groups[index] = group;
  }
}

export const groupsJsonSchema = () =>
  ajvToJsonSchema(
    {
      $schema: "http://json-schema.org/draft-07/schema#",
      $id: "https://rehorse.vandenoever.info/groups.schema.json",
      title: "Groups",
      description: "The group configuration of Rehorse",
    },
    groupArrayAjvSchema,
  );
