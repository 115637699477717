<template>
  <PdfMenu :options @change="updateOptions" @download="download()"> </PdfMenu>
  <AnnotationMenuButton :options @change="updateOptions" />
  <div class="pdfroute">
    <div v-if="status.isError">
      Could not load the pdf file.
      <div>{{ status.error }}</div>
    </div>
    <PdfView
      ref="pdf"
      :options
      group="props.group"
      :source="source"
      :annotations
      @change="updateOptions"
      @annotations-change="annotationsChange"
    >
    </PdfView>
    <AnnotationMenu
      :options
      @change="updateOptions"
      @color-change="colorChange"
    />
  </div>
</template>
<script setup lang="ts">
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  toRef,
  useTemplateRef,
} from "vue";
import { useRehorseStore } from "../store";
import PdfMenu from "../components/PdfMenu.vue";
import PdfView from "../components/PdfView.vue";
import AnnotationMenuButton from "../components/AnnotationMenuButton.vue";
import AnnotationMenu from "../components/AnnotationMenu.vue";
import { createPdfHandle } from "../loaders/pdf";
import { PdfViewOptions, viewMode } from "../components/PdfViewOptions";
import { PageAnnotations } from "../../shared/annotations";
import { api } from "../api-client";
import { ArrangementId, isId, PartId } from "../../shared/ids";

const props = defineProps<{
  group: string;
  url: string;
  filename: string;
}>();

const handle = createPdfHandle(toRef(() => props.url));
const status = computed(() => handle.status.value);

const storageKey = "fitWidth";

const initialFitWidth = (): boolean => {
  const f = localStorage.getItem(storageKey);
  if (f) {
    return f === "true";
  }
  // if no value was found, fit to width
  return true;
};
const pdf = useTemplateRef("pdf");
const options = ref<PdfViewOptions>({
  mode: viewMode,
  rotation: 0,
  pageFit: initialFitWidth() ? "fit-width" : "fit-height",
  color: "#000000",
});
const source = computed(() => {
  if (status.value.isReady) {
    return status.value.data;
  }
  return undefined;
});
const annotationsUrl = props.url
  .replace("/part/", "/annotations/")
  .replace(/\/[^/]+$/, "");
const store = useRehorseStore();
const annotations = store.getAnnotations(annotationsUrl);

async function annotationsChange(index: number, a: PageAnnotations) {
  const urlPart = props.url.split("/");
  const arrangementId = urlPart[3];
  const partId = urlPart[5];
  if (
    arrangementId &&
    isId<ArrangementId>(arrangementId) &&
    partId &&
    isId<PartId>(partId)
  ) {
    annotations.value.pageAnnotations[index] = a;
    await api.saveAnnotations({
      group: props.group,
      arrangementId,
      partId,
      partAnnotations: annotations.value,
    });
  }
}

const saveFitWidth = () => {
  const v: PdfViewOptions = options.value;
  localStorage.setItem(storageKey, (v.pageFit === "fit-width").toString());
};

function updateOptions(newOptions: PdfViewOptions) {
  options.value = newOptions;
  saveFitWidth();
}

function download() {
  if (pdf.value && "download" in pdf.value) {
    pdf.value.download(props.filename);
  }
}

async function colorChange(itemId: string | undefined, newColor: string) {
  if (itemId) {
    let change = false;
    for (const a of annotations.value.pageAnnotations) {
      for (const item of a.items) {
        if (item.id === itemId) {
          item.color = newColor;
          change = true;
        }
      }
    }
    if (change) {
      await store.saveAnnotations(annotationsUrl, annotations.value);
    }
  } else {
    options.value.color = newColor;
  }
}

let wakeLock: WakeLockSentinel | undefined;
onMounted(async () => {
  try {
    wakeLock = await navigator.wakeLock.request("screen");
  } catch (err) {
    if (err instanceof Error) {
      // the wake lock request fails - usually system related, such being low on battery
      console.log(`${err.name}, ${err.message}`);
    }
  }
  return store.loadAnnotations(annotationsUrl);
});
onUnmounted(async () => {
  if (wakeLock && !wakeLock.released) {
    await wakeLock.release();
  }
  handle.release();
});
</script>
<style>
div.pdfroute {
  display: flex;
  flex-direction: column;
  /* Set width and height with vw and vh as fallback for when dvw and dvh are
     not available. */
  width: 100vw;
  height: 100vh;
  width: 100dvw;
  height: 100dvh;
}
div.pdfcontainer {
  flex-grow: 1;
}
</style>
