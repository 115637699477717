<template>
  <Top />
  <div class="panel">
    <div class="panel-heading">
      <div class="level is-mobile">
        <div class="level-left">Arrangements</div>
      </div>
    </div>
    <table class="table is-bordered is-hoverable">
      <thead class="sticky has-background-inherit">
        <tr>
          <th>Arrangement</th>
          <td v-for="[id, part] in group.parts" :key="id" class="rotated">
            {{ part.name }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="[id, arrangement] in arrangements.arrangements"
          :key="id"
          @click="click(id)"
        >
          <th>{{ arrangement.title }}</th>
          <td v-for="[partId] in group.parts" :key="partId">
            <span v-if="arrangement.parts.has(partId)" class="is-primary"
              >+</span
            >
            <span v-else class="has-text-danger">-</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useRehorseStore } from "../store";
import Top from "../components/Top.vue";
import { ArrangementId } from "../../shared/ids";

const props = defineProps<{
  group: string;
}>();

const store = useRehorseStore();
const router = useRouter();
const group = store.getGroup(props.group);
const arrangements = store.getArrangements(props.group);

async function click(id: ArrangementId) {
  await router.push({
    name: "arrangement",
    params: { group: props.group, arrangement: id },
  });
}
</script>
<style>
table.table > thead > tr > th {
  vertical-align: bottom;
}
thead.sticky {
  position: sticky;
  top: 0px;
}
td.rotated {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  /*
  text-orientation: mixed;
 */
}
table.table > tbody > tr > td {
  text-align: center;
  vertical-align: middle;
}
table.table > tbody > tr {
  cursor: pointer;
}
</style>
