// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import "./assets/main.css";
import "./assets/rehorse.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { loadData } from "./store";
import App from "./App.vue";
import Agenda from "./routes/Agenda.vue";
import AppError from "./routes/AppError.vue";
import AppInfo from "./routes/AppInfo.vue";
import Arrangement from "./routes/Arrangement.vue";
import Arrangements from "./routes/Arrangements.vue";
import ArrangementsDetails from "./routes/ArrangementsDetails.vue";
import CacheInspector from "./routes/CacheInspector.vue";
import Concert from "./routes/Concert.vue";
import GroupSelection from "./routes/GroupSelection.vue";
import GroupView from "./routes/GroupView.vue";
import Login from "./routes/Login.vue";
import NotFound from "./routes/NotFound.vue";
import PdfRoute from "./routes/PdfRoute.vue";
import People from "./routes/People.vue";
import Pieces from "./routes/Pieces.vue";
import Player from "./routes/Player.vue";
import Playlist from "./routes/Playlist.vue";
import Playlists from "./routes/Playlists.vue";
import Rehearsal from "./routes/Rehearsal.vue";
import Rehorse from "./routes/Rehorse.vue";
import ResetPassword from "./routes/ResetPassword.vue";
import { fillCache } from "./cache";
import { logError } from "./errors";

const pinia = createPinia();

const routes: RouteRecordRaw[] = [
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  { name: "home", path: "/", component: GroupSelection },
  { name: "user", path: "/user", component: Login },
  {
    name: "reset-password",
    path: "/reset-password/:token?",
    component: ResetPassword,
    props: true,
  },
  { name: "group", path: "/:group", component: Rehorse, props: true },
  {
    name: "group-view",
    path: "/:group/view",
    component: GroupView,
    props: true,
  },
  { name: "agenda", path: "/:group/agenda", component: Agenda, props: true },
  {
    name: "concert",
    path: "/:group/agenda/concert/:concert",
    component: Concert,
    props: true,
  },
  {
    name: "rehearsal",
    path: "/:group/agenda/rehearsal/:rehearsal",
    component: Rehearsal,
    props: true,
  },
  {
    name: "playlist",
    path: "/:group/playlist/:playlist",
    component: Playlist,
    props: true,
  },
  {
    name: "playlists",
    path: "/:group/playlists",
    component: Playlists,
    props: true,
  },
  {
    name: "player",
    path: "/:group/player/:piece",
    component: Player,
    props: true,
  },
  {
    name: "pieces",
    path: "/:group/pieces",
    component: Pieces,
    props: true,
  },
  {
    name: "people",
    path: "/:group/people",
    component: People,
    props: true,
  },
  {
    name: "arrangements",
    path: "/:group/arrangements",
    component: Arrangements,
    props: true,
  },
  {
    name: "arrangements-details",
    path: "/:group/arrangements-details",
    component: ArrangementsDetails,
    props: true,
  },
  {
    name: "arrangement",
    path: "/:group/arrangement/:arrangement",
    component: Arrangement,
    props: true,
  },
  {
    name: "pdf",
    path: "/:group/pdf/:filename/:url",
    component: PdfRoute,
    props: true,
  },
  {
    name: "cache",
    path: "/cache",
    component: CacheInspector,
  },
  {
    name: "error",
    path: "/error",
    component: AppError,
  },
  {
    name: "app-info",
    path: "/app-info",
    component: AppInfo,
  },
  {
    name: "not-found",
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  /* eslint-enable @typescript-eslint/no-unsafe-assignment */
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeResolve(async (to, _from, next) => {
  const group = to.params["group"];
  if (typeof group === "string") {
    try {
      await loadData(group);
    } catch (e: unknown) {
      if (e instanceof Error) {
        next(e);
      } else {
        next(new Error(String(e), { cause: e }));
      }
      return;
    }
  }
  void fillCache();
  next();
});

router.onError(async (error: Error) => {
  logError(error);
  await router.push({ name: "error" });
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
createApp(App).use(pinia).use(router).mount("#app");
