import { ref } from "vue";
import { ErrorObject, serializeError } from "serialize-error";
import { api } from "./api-client";
import { version, lastModified, revision } from "../shared/version.json";
import { ErrorLogType } from "../shared/api";

interface ErrorLogEntry {
  error: Error;
  date: Date;
}

function getStack(): string | undefined {
  try {
    throw new Error();
  } catch (e) {
    if (e instanceof Error) {
      return e.stack;
    }
  }
  return;
}

export const logError = (...args: unknown[]) => {
  const mappedArgs: ErrorObject[] = args.map((a) => serializeError(a));
  // if none of the arguments has a stack, get one from this location
  const argsHaveAStack = args.some((arg) =>
    arg instanceof Error ? arg.stack : undefined,
  );
  const error: ErrorLogType = {
    version,
    lastModified,
    revision,
    error: mappedArgs,
  };
  if (!argsHaveAStack) {
    const stack = getStack();
    if (stack) {
      error.stack = stack;
    }
  }
  void api.logError(error);
  const json = JSON.stringify(mappedArgs);
  errors.value.push({ error: new Error(json), date: new Date() });
};
export const errors = ref<ErrorLogEntry[]>([]);
