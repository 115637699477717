import { StaticDecode, Type } from "@sinclair/typebox";
import { err, ok, Result } from "./result.js";
import { create_io, IO } from "./ajv.js";
import { groupIdSchema, lcNameSchema, uuidPattern, uuidSchema } from "./ids.js";
import { group_io } from "./group.js";
import { partAnnotationsSchema } from "./annotations.js";
import { arrangementIdSchema } from "./rehorse.js";

const jsonValueType = Type.Union([
  Type.String(),
  Type.Number(),
  Type.Boolean(),
  Type.Null(),
  Type.Unknown(), // TODO add json array and json object
]);
const jsonObjectType = Type.Record(Type.String(), jsonValueType);

const errorLogSchema = Type.Object({
  version: Type.String(),
  lastModified: Type.String(),
  revision: Type.String(),
  error: Type.Array(
    Type.Composite([
      Type.Object({
        name: Type.Optional(Type.String()),
        message: Type.Optional(Type.String()),
        stack: Type.Optional(Type.String()),
        cause: Type.Optional(Type.Unknown()),
        code: Type.Optional(Type.String()),
      }),
      jsonObjectType,
    ]),
  ),
  stack: Type.Optional(Type.String()),
});

export type ErrorLogType = StaticDecode<typeof errorLogSchema>;

const errorLogIo = create_io(errorLogSchema, []);

const namesSchema = Type.Record(Type.String(), Type.String());
const namesIo = create_io(namesSchema, []);
export type NamesType = StaticDecode<typeof namesSchema>;

const emptySchema = Type.Object({});
const emptyIo = create_io(emptySchema, []);
export type EmptyType = StaticDecode<typeof emptySchema>;

const newMemberSchema = Type.Object({
  id: Type.String({ pattern: uuidPattern }),
  name: Type.String(),
  email: Type.String(),
  role: Type.Union([Type.String({ pattern: uuidPattern }), Type.Null()]),
});
export type NewMember = StaticDecode<typeof newMemberSchema>;

const addMembersSchema = Type.Object({
  groupname: groupIdSchema,
  members: Type.Array(newMemberSchema),
  message: Type.String(),
});
const addMembersIo = create_io(
  {
    ...addMembersSchema,
    $defs: { lcname: lcNameSchema },
  },
  [lcNameSchema],
);
export type AddMembersType = StaticDecode<typeof addMembersSchema>;

const annotationsSchema = Type.Object({
  group: Type.String(),
  arrangementId: arrangementIdSchema,
  partId: Type.String({ pattern: uuidPattern }),
  partAnnotations: partAnnotationsSchema,
});

export type Annotations = StaticDecode<typeof annotationsSchema>;

const annotationsIo = create_io(
  { ...annotationsSchema, $defs: { uuid: uuidSchema } },
  [uuidSchema],
);

interface EndPoint<I, O> {
  input: IO<I>;
  output: IO<O>;
}

export type Return<T> = Promise<Result<T, Error>>;
const types = {
  logError: {
    input: errorLogIo,
    output: emptyIo,
  },
  names: {
    input: emptyIo,
    output: namesIo,
  },
  addMembers: {
    input: addMembersIo,
    output: emptyIo,
  },
  saveGroup: {
    input: group_io,
    output: emptyIo,
  },
  saveAnnotations: {
    input: annotationsIo,
    output: emptyIo,
  },
};
type Types = typeof types;
type ApiInput<Key extends keyof Types> = Parameters<
  Types[Key]["input"]["encode"]
>[0];
type ApiOutput<Key extends keyof Types> = Parameters<
  Types[Key]["output"]["encode"]
>[0];

export type Api = {
  [Key in keyof Types]: (i: ApiInput<Key>) => Return<ApiOutput<Key>>;
};

function wrapClientFunction<Key extends keyof Types, I, O>(
  name: Key,
  endpoint: EndPoint<I, O>,
  wrapper: (name: Key, data: string) => Return<unknown>,
): (i: I) => Return<O> {
  return async (i: I): Return<O> => {
    try {
      const json = endpoint.input.encode(i);
      const result = await wrapper(name, json);
      if (result.isErr) {
        return result;
      }
      return endpoint.output.decode(result.value);
    } catch (e) {
      if (e instanceof Error) {
        return err(e);
      }
      return err(new Error(JSON.stringify(e)));
    }
  };
}

export function wrapClient(
  wrapper: (name: keyof Api, data: string) => Return<unknown>,
) {
  const f: (k: keyof Types) => unknown = (k) =>
    wrapClientFunction(k, types[k], wrapper);
  return Object.fromEntries(
    Object.keys(types).map((k) => [k, f(k as keyof Types)]),
  ) as Api;
}

type Handlers<C> = {
  [Key in keyof Api]: (
    context: C,
    input: ApiInput<Key>,
  ) => Return<ApiOutput<Key>>;
};
function wrapFunction<Key extends keyof Types, C>(
  key: Key,
  endpoint: Types[Key],
  handlers: Handlers<C>,
): (context: C, input: unknown) => Return<string> {
  const f = handlers[key];
  return async (context: C, data: unknown): Return<string> => {
    const input = endpoint.input.decode(data);
    if (input.isErr) {
      return input;
    }
    const result = await f(context, input.value);
    if (result.isErr) {
      return result;
    }
    return ok(endpoint.output.encode(result.value));
  };
}

export function wrapServer<C, H>(
  wrapper: (f: (context: C, input: unknown) => Return<string>) => H,
  handlers: Handlers<C>,
) {
  return Object.fromEntries(
    Object.keys(types).map((k) => [
      k,
      wrapper(
        wrapFunction(k as keyof Types, types[k as keyof Types], handlers),
      ),
    ]),
  ) as { [Key in keyof Api]: H };
}
