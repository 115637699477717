<template>
  <Top />
  <h2 class="title">Desired files</h2>
  <div>{{ numberAvailable }} of {{ numberDesired }} are present.</div>
  <table class="table">
    <thead>
      <tr>
        <th>type</th>
        <th>title</th>
        <th>present</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="[url, props] in desiredUrls" :key="url">
        <td>{{ props.type }}</td>
        <td>{{ props.title ?? url }}</td>
        <td>
          <span v-if="props.present">☑</span>
          <button v-else @click="retrieve(url)">☐</button>
        </td>
      </tr>
    </tbody>
  </table>
  <h2 class="title">Cached files</h2>
  <div>
    <button class="button" :disabled="!files?.length" @click="clear()">
      Clear cache
    </button>
  </div>
  <table class="table">
    <thead>
      <tr>
        <td></td>
        <th align="center" @click="setOrder('date')">date</th>
        <th align="center" @click="setOrder('type')">type</th>
        <th align="center">group</th>
        <th align="center" @click="setOrder('title')">title</th>
        <th align="center" @click="setOrder('size')">size</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="file in files" :key="file.url">
        <td>
          <button class="button" @click="removeCacheEntry(file.url)">🗑️</button>
        </td>
        <td>{{ formatDate(file.date) }}</td>
        <td>{{ file.type }}</td>
        <td>{{ file.group }}</td>
        <td>{{ file.title ?? file.url }}</td>
        <td align="right">{{ formatSize(file.size) }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td></td>
        <td align="right">Total:</td>
        <td>{{ formatSize(totalSize) }}</td>
      </tr>
    </tfoot>
  </table>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import Top from "../components/Top.vue";
import {
  liveCacheFiles,
  clearCache,
  deleteFromCache,
  TitledOrder,
  Type,
} from "../cachedb";
import { getDesiredUrls, retrieveUrl } from "../cache";

const order = ref<TitledOrder>("date");
const files = liveCacheFiles(order);
const desiredUrls = ref<Map<string, UrlInfo>>(new Map());
const numberDesired = ref(0);
const numberAvailable = ref(0);
const totalSize = computed(() =>
  files.value.reduce((sum, v) => sum + v.size, 0),
);
const setOrder = (o: TitledOrder) => {
  order.value = o;
};

const formatDate = (d: Date) =>
  d.toISOString().substring(0, 16).replace("T", " ");
const formatSize = (size: number) => {
  if (size > 1024 * 1024) {
    return `${Math.floor(size / 1024 / 1024)} M`;
  }
  if (size > 1024) {
    return `${Math.floor(size / 1024)} k`;
  }
  return `${size}`;
};
interface UrlInfo {
  present: boolean;
  title: string;
  type: Type;
}
const updateDesiredUrls = async () => {
  const urls = await getDesiredUrls();
  const newDesiredUrls = new Map<string, UrlInfo>();
  for (const [url, props] of urls) {
    newDesiredUrls.set(url, {
      present: false,
      title: props.title,
      type: props.type,
    });
  }
  numberDesired.value = urls.size;
  numberAvailable.value = 0;
  for (const file of files.value) {
    const props = newDesiredUrls.get(file.url);
    if (props) {
      props.present = true;
      numberAvailable.value += 1;
    }
  }
  desiredUrls.value = newDesiredUrls;
};

watch(() => files, updateDesiredUrls, { immediate: true });

const updateList = async () => {
  await updateDesiredUrls();
};

watch([order, files], updateList, { immediate: true });

const clear = async () => {
  await clearCache();
  await updateList();
};

const removeCacheEntry = async (uri: string) => {
  await deleteFromCache(uri);
  await updateList();
};

const retrieve = async (url: string) => {
  await retrieveUrl(url);
  await updateDesiredUrls();
};
</script>
<style>
table > tr > th {
  cursor: pointer;
}
</style>
