import { Api, Return, wrapClient } from "../shared/api";
import { err, ok } from "../shared/result";
import { logError } from "./errors";

async function wrapper(name: keyof Api, data: string): Return<unknown> {
  try {
    return await post(name, data);
  } catch (e) {
    logError(e);
    if (e instanceof Error) {
      return err(e);
    }
    return err(new Error(JSON.stringify(e)));
  }
}

async function post(name: keyof Api, body: string): Return<unknown> {
  try {
    const response = await fetch(`api/${name}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body,
    });
    if (response.ok) {
      return ok(await response.json());
    } else {
      return err(new Error(await response.text()));
    }
  } catch (e) {
    if (e instanceof Error) {
      return err(e);
    } else {
      return err(new Error(JSON.stringify(e)));
    }
  }
}

export const api = wrapClient(wrapper);
