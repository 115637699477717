// SPDX-FileCopyrightText: 2023 Jos van den Oever <rehorse@vandenoever.info>
//
// SPDX-License-Identifier: AGPL-3.0-only

import { computed, ComputedRef, readonly, ref, Ref, watch } from "vue";
import { defineStore } from "pinia";
import type { Group } from "../shared/group";
import type { Mark, Piece, Playlist, Arrangement } from "../shared/rehorse";
import { PartAnnotations, annotationsIO } from "../shared/annotations";
import { io } from "../shared/rehorse";
import type { ArrangementId, PieceId, PlaylistId } from "../shared/ids";
import { Persons } from "./stores/people";
import { Arrangements } from "./stores/arrangements";
import {
  AgendaData,
  RehorseData,
  rehorseLoaderOptions,
} from "./loaders/rehorse";
import { online } from "./online";
import { user } from "./user";
import { cacheFile, hashValue } from "./cachedb";
import { rehorseJsonSha256s } from "./websocket";
import { groups, groupsHandle } from "./loaders/groups";
import { logError } from "./errors";

export interface SetPiece {
  index: PieceId;
  title: string;
  marks: Mark[];
}

function rehorseUrl(group: string) {
  return `groups/${group}/rehorse.json`;
}

/// Write the state to the server
async function writeData(group: string, state: RehorseData) {
  const json: string = io.encode({
    agenda: state.agenda,
    pieces: state.pieces,
    playlists: state.playlists,
    persons: state.persons.people,
    arrangements: state.arrangements.arrangements,
  });
  const blob = new Blob([json], { type: "application/json" });
  state.sha256 = await hashValue(blob);
  const url = rehorseUrl(group);
  try {
    // also keep this version locally
    await cacheFile(url, blob);
  } catch (_e) {
    //ignore
  }
  await fetch(rehorseUrl(group), {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: json,
  });
}

const mutableGroupsData = ref(new Map<string, RehorseData>());
export const groupsData = readonly(mutableGroupsData);
function getGroup(group: string): Group {
  if (!groups.value.isReady) {
    throw Error(`Group '${group}' was not found.`);
  }
  const g = groups.value.data.find((v) => v.groupname === group);
  if (!g) {
    throw Error(`Group '${group}' was not found.`);
  }
  return g;
}
export async function loadData(group: string) {
  await groupsHandle.value.wait();
  const sha256 = rehorseJsonSha256s.value.get(group);
  const data = mutableGroupsData.value.get(group);
  if (data && (!sha256 || data.sha256 === sha256)) {
    data.group = getGroup(group);
    mutableGroupsData.value.set(group, data);
    return;
  }
  const newData = await rehorseLoaderOptions.getter(
    rehorseUrl(group),
    (freshData) => {
      mutableGroupsData.value.set(group, freshData);
    },
  );
  mutableGroupsData.value.set(group, newData);
}
watch(groups, async () => {
  if (!groups.value.isReady) {
    return;
  }
  for (const group of groups.value.data) {
    await loadData(group.groupname);
  }
});
watch(rehorseJsonSha256s, async () => {
  for (const group of rehorseJsonSha256s.value.keys()) {
    await loadData(group);
  }
});

export function getGroupData(group: string) {
  return computed(() => mutableGroupsData.value.get(group));
}

export const useRehorseStore = defineStore("rehorse", () => {
  const annotations = new Map<string, Ref<PartAnnotations>>();
  function canEdit(group: string): boolean {
    if (!groups.value.isReady || !online.value) {
      return false;
    }
    const g = groups.value.data.find((v) => v.groupname === group);
    if (g && user.value) {
      return g.admins.includes(user.value.username);
    }
    return false;
  }
  function getPlaylists(group: string): ComputedRef<Map<PlaylistId, Playlist>> {
    return computed(() => getData(group).playlists);
  }
  function getPieces(group: string): ComputedRef<Map<PieceId, Piece>> {
    return computed(() => getData(group).pieces);
  }
  function getPersons(group: string): ComputedRef<Persons> {
    return computed(() => getData(group).persons);
  }
  function getAgenda(group: string): ComputedRef<AgendaData> {
    return computed(() => getData(group).agenda);
  }
  function getArrangements(group: string): ComputedRef<Arrangements> {
    return computed(() => getData(group).arrangements);
  }
  function getArrangement(
    group: string,
    arrangement: ArrangementId,
  ): Arrangement {
    const data = getData(group);
    const a = data.arrangements.arrangements.get(arrangement);
    if (!a) {
      throw Error(`Arrangement '${arrangement}' was not found.`);
    }
    return a;
  }
  function hasPlaylist(group: string, playlist: PlaylistId): boolean {
    const data = getData(group);
    const pl = data.playlists.get(playlist);
    return pl !== undefined;
  }
  function getPlaylist(group: string, playlist: PlaylistId): Playlist {
    const data = getData(group);
    const pl = data.playlists.get(playlist);
    if (!pl) {
      throw Error(`Playlist '${playlist}' was not found.`);
    }
    return pl;
  }
  function setPlaylist(group: string, id: PlaylistId, playlist: Playlist) {
    const data = getData(group);
    data.playlists.set(id, playlist);
  }
  function getPiece(group: string, uuid: PieceId): Piece {
    const data = getData(group);
    const piece = data.pieces.get(uuid);
    if (!piece) {
      throw Error(`Piece '${uuid}' was not found.`);
    }
    return piece;
  }
  async function addPiece(group: string, piece_id: PieceId, piece: Piece) {
    const data = getData(group);
    data.pieces.set(piece_id, piece);
    await writeData(group, data);
  }
  function getData(group: string): RehorseData {
    const d = mutableGroupsData.value.get(group);
    if (!d) {
      throw Error(`Data for group '${group}' was not found.`);
    }
    return d;
  }
  async function loadAnnotations(url: string) {
    const a = getAnnotations(url);
    if (a.value.pageAnnotations.length !== 0) {
      return;
    }
    try {
      const response = await fetch(url);
      const result = annotationsIO.decode(await response.json());
      if (result.isErr) {
        logError(result.value);
      } else {
        a.value = result.value;
      }
    } catch (error) {
      logError(error);
    }
  }
  function getAnnotations(url: string): Ref<PartAnnotations> {
    let a = annotations.get(url);
    if (!a) {
      a = ref({ pageAnnotations: [] });
      annotations.set(url, a);
    }
    return a;
  }
  async function changePiece(group: string, setPiece: SetPiece) {
    const data = getData(group);
    const piece = data.pieces.get(setPiece.index);
    if (piece) {
      piece.title = setPiece.title;
      piece.marks = setPiece.marks;
      await writeData(group, data);
    }
  }
  async function save(group: string) {
    const data = getData(group);
    await writeData(group, data);
  }
  async function saveAnnotations(url: string, newAnnotations: PartAnnotations) {
    const a = annotations.get(url);
    if (a) {
      a.value = newAnnotations;
    }
    await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: annotationsIO.encode(newAnnotations),
    });
  }
  return {
    addPiece,
    annotations,
    canEdit,
    changePiece,
    getAgenda,
    getAnnotations,
    getArrangement,
    getArrangements,
    getGroup,
    getPersons,
    getPiece,
    getPieces,
    getPlaylist,
    getPlaylists,
    groups,
    hasPlaylist,
    loadAnnotations,
    save,
    saveAnnotations,
    setPlaylist,
  };
});
export type RehorseStore = ReturnType<typeof useRehorseStore>;
