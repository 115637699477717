<template>
  <tr>
    <td>
      <div class="field">
        <div class="control">
          <input
            v-model.trim="name"
            class="input"
            required
            type="text"
            @change="change"
          />
        </div>
        <p v-if="validEmail && email && !name" class="help is-danger">
          A name is needed
        </p>
      </div>
    </td>
    <td>
      <div class="field">
        <div class="control has-icons-left">
          <input
            v-model.trim="email"
            class="input"
            for="email"
            required
            type="email"
            autocomplete="email"
            inputmode="email"
            @change="change"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
        </div>
        <p v-if="!validEmail" class="help is-danger">This email is invalid</p>
      </div>
    </td>
    <td>
      <div class="field">
        <div class="control">
          <div class="select">
            <select v-model="role" @change="change">
              <option disabled selected value>select an option</option>
              <option v-for="[id, part] of roles" :key="id" :value="id">
                {{ part.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { EMAIL_RE } from "../../shared/email";
import { PartId } from "../../shared/ids";
import { Part } from "../../shared/group";
import { NewMember } from "../../shared/api";
const props = defineProps<{
  member: NewMember;
  roles: Map<PartId, Part>;
}>();
const emit = defineEmits<(e: "change", newMember: NewMember) => void>();
const name = ref(props.member.name);
const email = ref(props.member.email);
const role = ref(props.member.role);
const validEmail = computed(
  () => email.value.length === 0 || EMAIL_RE.test(email.value),
);
function change() {
  email.value = email.value.toLowerCase();
  emit("change", {
    id: props.member.id,
    name: name.value,
    email: email.value,
    role: role.value,
  });
}
</script>
